import axios from "axios";
import { Base64 } from "js-base64";
import { ElMessageBox,ElMessage } from "element-plus";
let instance = axios.create({
    repponseType:"json",
    headers:{"content-type":"application/json"},
    timeout:50000
})

instance.interceptors.request.use(
    config=>{
        let token = localStorage.getItem('token')
        if(token){
            config.headers.token = token;
        }
        return config;
    },
    error=>{
        return Promise.rejecte()
    }
)

instance.interceptors.response.use(
    response=>{
        console.log(response);
        if(response.data.msg!=''){
            ElMessage.error(response.data.msg);
        }
        return response.data;
    },
    error=>{
       if(error.response){
         let httpCode = error.response.status;
         switch(httpCode){
            case 400: ElMessage.error(response.data.msg);
            case 403:
                ElMessageBox.alert("用户登录信息已过期","提示",
                {confirmButtonText:"好的",
                type:"error"
                })
                .then(res=>{
                    //跳转登录页面
                    window.location.href="/";
                })
                .catch(error=>{

                })
            break;
         }
       }
       return Promise.reject(error)
    }
)
export default instance
