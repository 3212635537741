import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import request from './utils/request'
const app = createApp(App);
app.config.globalProperties.$request=request;
app.use(router).use(ElementPlus,{locale: zhCn}).mount('#app')
