import {createRouter,createWebHashHistory} from 'vue-router';

const routes=[
{
    path:"/",
    name:"login",
    component:()=>import(/*webpackChunkName:'Login'*/ '@/page/login.vue')
},
{
    path:"/index",
    name:"index",
    component:()=>import(/*webpackChunkName:'Index'*/ '@/page/index.vue'),
    redirect:"/index/signal",
    children:[
        {
            path:"signal",
            name:"signal",
            component:()=>import(/*webpackChunkName:'Index'*/ '@/page/signal.vue'),
        },
        {
            path:"cost",
            name:"cost",
            component:()=>import(/*webpackChunkName:'Index'*/ '@/page/cost.vue'),
        },
        {
            path:"peak",
            name:"peak",
            component:()=>import(/*webpackChunkName:'Index'*/ '@/page/peak.vue'),
        },
        {
            path:"fault",
            name:"fault",
            component:()=>import(/*webpackChunkName:'Index'*/ '@/page/fault.vue'),
        },
        {
            path:"overview",
            name:"overview",
            component:()=>import(/*webpackChunkName:'Index'*/ '@/page/overview.vue'),
        },
        {
            path:"account",
            name:"account",
            component:()=>import(/*webpackChunkName:'Index'*/ '@/page/account.vue'),
        },
        {
            path:"action",
            name:"action",
            component:()=>import(/*webpackChunkName:'Index'*/ '@/page/action.vue'),
        },
        {
            path:"vehicle",
            name:"vehicle",
            component:()=>import(/*webpackChunkName:'Index'*/ '@/page/vehicle.vue'),
        }
    ]
}
]
    
const router = createRouter({
    history: createWebHashHistory(),
    routes
})
export default router;

